'use client';

import React, { JSX, ReactNode } from 'react';
import { LazyMotion, domAnimation, m } from 'framer-motion';
interface ComponentTransitionProps {
  children: ReactNode;
}
const ComponentTransition = ({
  children
}: ComponentTransitionProps): JSX.Element => <LazyMotion features={domAnimation} data-sentry-element="LazyMotion" data-sentry-component="ComponentTransition" data-sentry-source-file="ComponentTransition.tsx">
    <m.div initial="componentInitial" animate="componentAnimate" exit="componentExit" transition={{
    duration: 1.3
  }} variants={{
    componentInitial: {
      opacity: 0
    },
    componentAnimate: {
      opacity: 1,
      transition: {
        delay: 1,
        duration: 0.3
      }
    },
    componentExit: {
      opacity: 0,
      transition: {
        delay: 1,
        duration: 0.3
      }
    }
  }} data-sentry-element="unknown" data-sentry-source-file="ComponentTransition.tsx">
      {children}
    </m.div>
  </LazyMotion>;
export default ComponentTransition;